const middleware = {}

middleware['i18n'] = require('../app/middleware/i18n.ts')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['library'] = require('../app/middleware/library.ts')
middleware['library'] = middleware['library'].default || middleware['library']

middleware['localisation'] = require('../app/middleware/localisation.ts')
middleware['localisation'] = middleware['localisation'].default || middleware['localisation']

middleware['track'] = require('../app/middleware/track.ts')
middleware['track'] = middleware['track'].default || middleware['track']

middleware['trailingSlash'] = require('../app/middleware/trailingSlash.ts')
middleware['trailingSlash'] = middleware['trailingSlash'].default || middleware['trailingSlash']

export default middleware
